<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
      <Bookmarks />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <Locale />
      <SearchBar />
      <CartDropdown />
      <NotificationDropdown />
      <UserDropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
// import { BLink, BNavbarNav } from 'bootstrap-vue';
// import Bookmarks from './components/Bookmarks.vue';
// import Locale from './components/Locale.vue';
// import SearchBar from './components/SearchBar.vue';
// import CartDropdown from './components/CartDropdown.vue';
// import NotificationDropdown from './components/NotificationDropdown.vue';
// import UserDropdown from './components/UserDropdown.vue';

export default {
  name: 'AppNavbarVerticalLayout',

  components: {
    // BLink,
    // Navbar Components
    // BNavbarNav,

    // Bookmarks,
    // Locale,
    // SearchBar,
    // CartDropdown,
    // NotificationDropdown,
    // UserDropdown,
    Bookmarks: () => import('./components/Bookmarks.vue' /* webpackChunkName: "Bookmarks" */),
    Locale: () => import('./components/Locale.vue' /* webpackChunkName: "Locale" */),
    SearchBar: () => import('./components/SearchBar.vue' /* webpackChunkName: "SearchBar" */),
    CartDropdown: () => import('./components/CartDropdown.vue' /* webpackChunkName: "CartDropdown" */),
    NotificationDropdown: () => import('./components/NotificationDropdown.vue' /* webpackChunkName: "NotificationDropdown" */),
    UserDropdown: () => import('./components/UserDropdown.vue' /* webpackChunkName: "UserDropdown" */),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
